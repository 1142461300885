import React, { useState } from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import classes from "./Form.module.scss";

const Form = ({ onFormSubmit, isUpload, setIsUpload }) => {
  const [term, setTerm] = useState("");
  const [queryType, setQueryType] = useState("sku");
  const placeholder = {
    sku: "Enter SKU separated by comma",
    collection: "Enter a collection ID",
  };
  const onSubmit = (event) => {
    event.preventDefault();

    onFormSubmit(term, queryType);
  };

  const handleSwitchChange = (event) => {
    setIsUpload(event.target.checked);
  };

  return (
    <div className={classes.formContainer}>
      <form className="" onSubmit={onSubmit} autoComplete="off">
        <FormControl
          classes={{
            root: classes.select,
          }}
          style={{ minWidth: "120px", marginRight: "10px" }}
        >
          <InputLabel id="search-by-select-label">Seacrch by</InputLabel>
          <Select
            labelId="search-by-select-label"
            id="search-by"
            value={queryType}
            onChange={(event) => setQueryType(event.target.value)}
          >
            <MenuItem value="sku">SKU</MenuItem>
            <MenuItem value="collection">Collection</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: "250px", marginRight: "10px" }}>
          <InputLabel id="sku-input-label">{placeholder[queryType]}</InputLabel>
          <Input
            labelId="sku-input-label"
            defaultValue={term}
            onChange={(event) => setTerm(event.target.value)}
            inputProps={{ "aria-label": "description" }}
            required
          />
          {queryType === "sku" && (
            <span className={classes.smallInfo}>
              Sku in lowercase i.e: a1211{" "}
            </span>
          )}
        </FormControl>
        <Button
          classes={{
            root: classes.button,
          }}
          variant="contained"
          type="submit"
        >
          Get Images
        </Button>
        <div className={classes.containerSwitch}>
          <span>{isUpload ? "Upload ON" : "Upload OFF"}</span>
          <Switch
            checked={isUpload}
            onChange={handleSwitchChange}
            name="isUpload"
            inputProps={{ "aria-label": "checkbox" }}
            classes={{
              checked: classes.check,
              track: classes.line,
            }}
          />
        </div>
      </form>
    </div>
  );
};

Form.propTypes = {
  isUpload: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  setIsUpload: PropTypes.func.isRequired,
};

export default Form;

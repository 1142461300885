import axios from "axios";

/*
 * Create axios instance
 */
const aws = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_AWS_BASE_URL
      : process.env.REACT_APP_AWS_BASE_URL_DEV,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
 * Upload images
 * @param images = array of object (length maximum 100).
 */
const postImages = (images) => aws.post("/upload", JSON.stringify(images));

export default postImages;

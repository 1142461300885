import axios from "axios";
import oauth from "axios-oauth-client";
import tokenProvider from "axios-token-interceptor";

/*
 * Create axios instance
 */
const bynder = axios.create({
  baseURL: "https://assets.kathmanduglobal.com/",
});

/*
 * Get access token
 */
const getAccessToken = oauth.client(bynder, {
  url: "v6/authentication/oauth2/token",
  grant_type: "client_credentials",
  client_id: process.env.REACT_APP_BYNDER_CLIENT_ID,
  client_secret: process.env.REACT_APP_BYNDER_CLIENT_SECRET,
  scope: "asset:read collection:read",
});

/*
 * Set the token before doing the axios request

 */
bynder.interceptors.request.use(
  tokenProvider({
    token: getAccessToken().then((res) => res.access_token),
  })
);

/*
 * Get assets by property (stylecode)
 */
export const getAssetsByStyleCode = (styleCode) =>
  bynder.get(
    `api/v4/media/?includeMediaItems=1&limit=100&property_StyleCode=${styleCode}`
  );

/*
 * Get assets by collection ID
 */
export const getAssetsByCollection = (id, page) =>
  bynder.get(
    `api/v4/media/?includeMediaItems=1&limit=1000&count=1&collectionId=${id}&page=${page}`
  );

/*
 * Get collection by collection ID
 */
export const getCollection = (id) => bynder.get(`api/v4/collections/${id}`);

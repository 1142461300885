import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Results from "../Results/Results";
import Form from "../Form/Form";
import useSkus from "../../hooks/useSkus";
import "../../styles/global.scss";
import classes from "./App.module.scss";
import Header from "../Header/Header";
import CircularLoader from "../CircularLoader/CircularLoader";

const App = () => {
  const [
    rows,
    columns,
    isUpload,
    loading,
    onFormSubmit,
    progress,
    setIsUpload,
    error,
  ] = useSkus();

  const listErrors = error && error.map((err) => <div key={err}>{err}</div>);

  return (
    <div className={classes.body}>
      <Header />
      <h1>Upload images</h1>
      <Form
        onFormSubmit={onFormSubmit}
        isUpload={isUpload}
        setIsUpload={setIsUpload}
      />
      <div className={classes.resultsContainer}>
        {listErrors && listErrors.length !== 0 && (
          <div className={classes.error}>{listErrors}</div>
        )}
        {loading.show ? (
          <CircularLoader text={loading.text} />
        ) : (
          <>
            {progress !== 100 && isUpload && (
              <div className={classes.progress}>
                <LinearProgress
                  classes={{
                    root: classes.progressBar,
                  }}
                  variant="determinate"
                  value={progress}
                />
                <span>Uploading images to S3</span>
              </div>
            )}
            <Results columns={columns} rows={rows} isUpload={isUpload} />
          </>
        )}
      </div>
    </div>
  );
};

export default App;

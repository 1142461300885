// Chunk array into define sizes arrays
// Return an array of arrays
export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

// Transform object to array and keeping the object order
export const sortObjectToArray = (obj) => {
  const keys = Object.keys(obj).sort();
  const newArray = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    newArray.push(obj[key]);
  }
  return newArray;
};

// Create SKU like follow : STYLECODE/COLOURCODE
export const createSku = (asset) => {
  if (asset.property_StyleCode && asset.property_ColourCode) {
    return `${asset.property_StyleCode.toUpperCase()}/${asset.property_ColourCode.toUpperCase()}`;
  }
  return null;
};

// Rewrite the image url without "Online_Version-" or without JPG_Original-"
export const rewriteImgUrl = (url) => {
  if (url.includes("Online_Version-")) {
    return url.replace("Online_Version-", "");
  }
  if (url.includes("JPG_Original-")) {
    return url.replace("JPG_Original-", "");
  }
  return null;
};

// Get the asset filename with or without file extension (deleting the full url path)
export const getAssetFilename = (url, fileExentsion = false) => {
  if (!url) {
    return null;
  }

  if (fileExentsion) {
    return url.substring(url.lastIndexOf("/") + 1);
  }
  return url.substring(url.lastIndexOf("/") + 1).replace(/\.[^/.]+$/, "");
};

// Extract style_code, size code from full sku (i.e: A1224/NQX/10, A1224/NQX/XS)
export const extractInfoFromSku = (fullSku) => {
  const extract = fullSku.split("/");

  if (!extract) {
    return null;
  }

  return {
    styleCode: extract[0].toLowerCase(),
    colorCode: extract[1].toLowerCase(),
  };
};

// Check if url is valid
export const fetchImage = (url) =>
  fetch(url, { method: "HEAD" })
    .then((res) => res.ok)
    // eslint-disable-next-line no-console
    .catch((err) => console.error("Error:", err));

import axios from "axios";
import tokenProvider from "axios-token-interceptor";

/*
 * Create axios instance
 */
const magento = axios.create({
  baseURL: "https://app-qa.kmddev.co.nz/",
  headers: { "Content-Type": "application/json" },
});

/*
 * Get access token
 */
const getAccessToken = magento.post("rest/V1/integration/admin/token", {
  username: process.env.REACT_APP_MAGENTO_USERNAME,
  password: process.env.REACT_APP_MAGENTO_PASSWORD,
});

/*
 * Set the token before doing the axios request

 */
magento.interceptors.request.use(
  tokenProvider({
    token: getAccessToken.then((resp) => resp.data),
  })
);

/*
 * Get children by sku (i.e. a1221)
 */
const getProductVariantsBySku = (sku) =>
  magento.get(`rest/nz_eng/V1/configurable-products/${sku}%2f/children`);

export default getProductVariantsBySku;

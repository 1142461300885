/* eslint-disable no-unused-vars */
import React from "react";
import { arrayOf, bool, number, shape, string } from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import classes from "./Results.module.scss";

// Add the export button in the toolbar
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const Results = ({ rows, columns, assets, isUpload }) => {
  if (rows.length === 0) {
    return null;
  }

  return (
    <div className="results">
      <h2 className={classes.title}>Results</h2>
      <div>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </div>
  );
};

Results.propTypes = {
  rows: arrayOf(
    shape({
      id: number,
      sku: string,
      base_image: string,
      small_image: string,
      thumbnail_image: string,
      swatch_image: string,
      additional_images: string,
    })
  ).isRequired,
  columns: arrayOf(
    shape({
      field: string.isRequired,
      headerName: string.isRequired,
      width: number,
    })
  ).isRequired,
  assets: arrayOf.isRequired,
  isUpload: bool.isRequired,
};

export default Results;

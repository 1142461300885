import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { string } from "prop-types";
import classes from "./CircularLoader.module.scss";

const CircularLoader = ({ text }) => (
  <div className={classes.CircularLoader}>
    <CircularProgress />
    <span>{text}</span>
  </div>
);

CircularLoader.propTypes = {
  text: string,
};

CircularLoader.defaultProps = {
  text: "Loading",
};

export default CircularLoader;

/* eslint-disable no-loop-func */
import { useState } from "react";
import sortObject from "sort-object-keys";
import {
  getAssetsByStyleCode,
  getAssetsByCollection,
  getCollection,
} from "../api/bynder";
import getProductVariantsBySku from "../api/magento";
import postImages from "../api/aws";
import {
  extractInfoFromSku,
  getAssetFilename,
  rewriteImgUrl,
  fetchImage,
  sortObjectToArray,
  chunkArray,
} from "../utils/utils";

const DEFAULT_LOADING_STATE = {
  show: false,
  text: "Getting images from bynder ...",
};

const useSkus = () => {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(DEFAULT_LOADING_STATE);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  /**
   * Sanity check if there isn't anything wrong in the returned asset
   *
   * @param {array} asset
   * @returns {bool} True if asset is valid otherwise false
   */
  const checkValidityAsset = (asset) => {
    // Check if asset exists
    if (!asset) {
      setError((prevState) => [...prevState, `Media doesn't exist.`]);
      return false;
    }

    // Take Online version but fallback to JPG original
    const mediaUrl =
      asset.thumbnails["Online Version"] || asset.thumbnails["JPG Original"];

    // Check if Online version exists
    if (!mediaUrl) {
      setError((prevState) => [
        ...prevState,
        `${asset.name} - no public URL (Online Version or JPG Original)`,
      ]);
      return false;
    }
    // Check if stylecode (term) is present in URL
    if (
      !asset.property_StyleCode ||
      !asset.property_StyleCode === undefined ||
      !mediaUrl.includes(asset.property_StyleCode.toLowerCase())
    ) {
      setError((prevState) => [
        ...prevState,
        `${asset.name} - public URL doesn't match the Style Code`,
      ]);
      return false;
    }
    // Check if colourCode (term) is present in URL
    if (
      !asset.property_ColourCode ||
      !asset.property_ColourCode === undefined ||
      !mediaUrl.includes(asset.property_ColourCode.toLowerCase())
    ) {
      setError((prevState) => [
        ...prevState,
        `${asset.name} - public URL doesn't match the Colour Code`,
      ]);
      return false;
    }
    return true;
  };

  /**
   * Get the assets From Bynder
   *
   * @param {string} term term to search in Bynder either single sku (i.e. a1224), separeted coma sku (a1224, a1225) or collection ID from Bynder
   * @param {string} queryType Type of query, to be able to get "collection" or "default"
   * @returns {array} Returns an array of object
   */
  const search = async (term, queryType) => {
    // If it's a collection search
    if (queryType === "collection") {
      try {
        // Check if the collection exsits before fetching data.
        const singleCollection = await getCollection(term).then(
          (resp) => resp.data
        );
        if (!singleCollection) {
          return null;
        }
        // Get asssets by collection
        const collection = await getAssetsByCollection(term, 1)
          .then((resp) => resp.data)
          .then(async (dataCollection) => {
            let allAssets = dataCollection.media;
            // If count is above 1000, make other queries split by 1000
            if (dataCollection.count.total > 1000) {
              const pages = Math.ceil(dataCollection.count.total / 1000);
              for (let i = 2; i <= pages; i += 1) {
                // We want to run queries one after the other to avoid overloading API
                // eslint-disable-next-line no-await-in-loop
                await getAssetsByCollection(term, i).then((resp) => {
                  if (resp.data.media.length !== 0) {
                    // Only takes assets that have been validated in checkValidityAsset
                    const filteredAssetsSku = resp.data.media.filter((asset) =>
                      checkValidityAsset(asset)
                    );
                    allAssets = [...allAssets, ...filteredAssetsSku];
                  } else {
                    setError((prevState) => [
                      ...prevState,
                      `${term} is not a valid SKU.`,
                    ]);
                  }
                });
              }
            }
            return allAssets;
          });
        return collection;
      } catch (e) {
        // Error 😨
        if (e.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          setError((prevState) => [...prevState, e.response.data.message]);
        } else {
          // Something happened in setting up the request and triggered an Error
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
    // If it's a SKU search
    else {
      try {
        // Split term in case it's a sepated comma term
        const arrayTerms = term.split(",").map((t) => t.trim());
        let allAssets = [];
        // We want to run queries one after the other to avoid overloading API
        // eslint-disable-next-line no-restricted-syntax
        for (const singleTerm of arrayTerms) {
          // eslint-disable-next-line no-await-in-loop
          const assetsSku = await getAssetsByStyleCode(singleTerm).then(
            (resp) => resp.data
          );
          if (assetsSku.length !== 0) {
            // Only takes assets that have been validated in checkValidityAsset
            const filteredAssetsSku = assetsSku.filter((asset) =>
              checkValidityAsset(asset)
            );
            allAssets = [...allAssets, ...filteredAssetsSku];
          } else {
            setError((prevState) => [
              ...prevState,
              `${singleTerm} is not a valid SKU.`,
            ]);
          }
        }
        return allAssets;
      } catch (e) {
        // Error 😨
        if (e.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          setError(e.response.data.message);
        } else {
          // Something happened in setting up the request and triggered an Error
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
    return null;
  };

  /**
   * Map all the assets by grouping images in the same SKU to create CSV rows
   *
   * @param {array} assetsArray
   * @returns {array} Return an array of object per simple sku (i.e. A1224/023/S) to create Rows for the CSV
   * [{
   *   id: {number}
   *   sku: {string}
   *   base_image: {string}
   *   small_image: {string}
   *   thumbnail_image: {string}
   *   swatch_image: {string}
   *   additional_images: {string}
   * }]
   */
  const mapAssets = async (assetsArray) => {
    try {
      // Create an array with all styleCode (simple sku i.e. a1211) and remove duplicates using new Set
      const simpleSkusArray = [
        ...new Set(assetsArray.map((item) => item.property_StyleCode)),
      ];

      const mapAssetsForCsv = await Promise.all(
        simpleSkusArray.map(async (simpleSku) => {
          // Get the Sizes from Magento for each single Sku (i.e. A1211) and return array of children sku [A1211/N01/S, A1211/N01/M, A1211/N01/L]
          const magentoSku = await getProductVariantsBySku(simpleSku).then(
            (resp) => resp.data.map((item) => item.sku)
          );

          const mapAssetsInObject = magentoSku.map((sku) => {
            const bynderUrl = {};
            const { styleCode, colorCode } = extractInfoFromSku(sku);
            // Get the assets that match the magento full sku (i.e. A1211/N01/S) and create an object
            assetsArray
              .filter(
                (asset) =>
                  asset?.property_StyleCode.toLowerCase() === styleCode &&
                  asset?.property_ColourCode.toLowerCase() === colorCode
              )
              .forEach((asset) => {
                /*
                 ** Add the assets url to the url object for unique skus
                 ** Validation is done in useSkus hooks (check if that URL exists)
                 */
                const urlAsset = asset.thumbnails["Online Version"]
                  ? asset.thumbnails["Online Version"]
                  : asset.thumbnails["JPG Original"];
                if (urlAsset) {
                  // Use the first letter of the filename as object key, for sortering
                  bynderUrl[getAssetFilename(urlAsset).slice(-1)] = {
                    url: urlAsset,
                    filename: getAssetFilename(rewriteImgUrl(urlAsset), true),
                  };
                }
              });
            return {
              sku,
              bynder_url: sortObject(bynderUrl), // order object key ASC
            };
          });
          return mapAssetsInObject;
        })
      );

      // Create Rows for the csv
      return mapAssetsForCsv
        .flat() // Flat array in 1 level
        .filter((asset) => Object.keys(asset.bynder_url).length > 0) // Remove assets without bynder_url keys
        .map((asset, index) => {
          // Return the filename if isUpload is true, or return the full Bynder url;
          // eslint-disable-next-line no-nested-ternary
          const defaultImage = asset.bynder_url.a
            ? isUpload
              ? asset.bynder_url.a.filename
              : asset.bynder_url.a.url
            : null;
          const additionalImages = sortObjectToArray(asset.bynder_url);

          return {
            id: index,
            sku: asset.sku,
            base_image: defaultImage,
            small_image: defaultImage,
            thumbnail_image: defaultImage,
            swatch_image: defaultImage,
            additional_images: additionalImages
              .filter((assetData) => !assetData.url.includes("_a.")) // Remove _a asset as it's use for base_image
              .map(
                (singleAsset) =>
                  isUpload ? singleAsset.filename : singleAsset.url // Return the filename if isUpload is true, or return the full Bynder url;
              )
              .join(","),
          };
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError((prevState) => [
        ...prevState,
        `There was an error while fetching sizes from Magento`,
      ]);
      return null;
    }
  };

  /**
   * Upload assets to AWS (s3 bucket)
   *
   * @param {array} assets Array of assets from Bynder
   */
  const uploadToAws = async (assets) => {
    try {
      const assetsRewrite = await Promise.all(
        assets.map(async (asset) => {
          const onlineVersionExist =
            asset.thumbnails["Online Version"] &&
            (await fetchImage(asset.thumbnails["Online Version"]));

          if (onlineVersionExist) {
            return {
              imageUrl: asset.thumbnails["Online Version"],
              name: getAssetFilename(
                rewriteImgUrl(asset.thumbnails["Online Version"]),
                true
              ),
              id: asset.id,
            };
          }

          const jpgVersionExist =
            asset.thumbnails["JPG Original"] &&
            (await fetchImage(asset.thumbnails["JPG Original"]));

          if (jpgVersionExist) {
            return {
              imageUrl: asset.thumbnails["JPG Original"],
              name: getAssetFilename(
                rewriteImgUrl(asset.thumbnails["JPG Original"]),
                true
              ),
              id: asset.id,
            };
          }

          return null;
        })
      );

      const assetsChunk = chunkArray(assetsRewrite, 100); // Split array by array of 100 items to avoid overloading AWS serverless function
      const progressGap = assetsChunk.length > 0 ? 100 / assetsChunk.length : 0; // Calculate the progress of the upload

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, assetsArray] of assetsChunk.entries()) {
        const progressValue = (index + 1) * progressGap;
        // eslint-disable-next-line no-await-in-loop
        await postImages(assetsArray); // Need to do the error handling
        setProgress(progressValue);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError((prevState) => [
        ...prevState,
        `There was an error while uploading files to AWS`,
      ]);
    }
  };

  /**
   *
   * @param {string} termEntered term in the input form either single sku (i.e. a1224), separeted coma sku (a1224, a1225) or collection ID from Bynder
   * @param {string} queryTypeSelected Query type selected from the From either "collection" or "sku"
   */
  const onFormSubmit = async (termEntered, queryTypeSelected) => {
    try {
      // Set Default value
      setLoading({
        show: true,
        text: DEFAULT_LOADING_STATE.text,
      });
      setError([]);
      // Run Bynder Search to get array of assets
      const assets = await search(termEntered, queryTypeSelected);
      setLoading((prevState) => ({
        show: prevState.show,
        text: "Grabbing sizes from Magento...",
      }));
      // Start upload to AWS
      uploadToAws(assets);
      // Map assets to match csv model
      await mapAssets(assets).then((response) => {
        setRows(response);
        setLoading((prevState) => ({
          show: false,
          text: prevState.text,
        }));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError((prevState) => [
        ...prevState,
        `There was an error while submitting`,
      ]);
    }
  };

  // Create the columns for the Data Grid
  const columns = [
    { field: "sku", headerName: "sku", width: 150 },
    { field: "base_image", headerName: "base_image", width: 250 },
    { field: "small_image", headerName: "small_image", width: 250 },
    { field: "thumbnail_image", headerName: "thumbnail_image", width: 250 },
    { field: "swatch_image", headerName: "swatch_image", width: 250 },
    {
      field: "additional_images",
      headerName: "additional_images",
      width: 1000,
    },
  ];

  return [
    rows,
    columns,
    isUpload,
    loading,
    onFormSubmit,
    progress,
    setIsUpload,
    error,
  ];
};

export default useSkus;
